import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth"
import { auth } from "./init"

/**
 * Verifies a sign-in link and signs the user in. The function returns true if a
 * sign-in link was verified.
 *
 * ! Should only be called if the user is not signed in, to prevent errors.
 *
 * @see https://firebase.google.com/docs/auth/web/email-link-auth
 */
export async function verifySignInLink(): Promise<boolean> {
  if (typeof window === "undefined") {
    return false
  }
  if (isSignInWithEmailLink(auth, window.location.href)) {
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    let email =
      window.localStorage.getItem("frontender-auth-email") || undefined
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      email =
        // eslint-disable-next-line no-alert
        window.prompt("Please provide your email for confirmation.") ||
        undefined
    }
    // The client SDK will parse the code from the link for you.
    if (email) {
      await signInWithEmailLink(auth, email, window.location.href)
      // Clear email from storage.
      window.localStorage.removeItem("frontender-auth-email")
    }
    return true
  }
  return false
}
