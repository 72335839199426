import "../styles.css"

import type { AppProps } from "next/app"
import { ReactElement, ReactNode, useEffect, useState } from "react"
import { Frontender } from "types"
import { collection, getDocs, query, where } from "firebase/firestore"
import { posthog } from "posthog-js"
import { useRouter } from "next/router"
import Script from "next/script"
import fetchJsonp from "fetch-jsonp"
import { NextPage } from "next"
import { db } from "../utils/init"
import { useAuth } from "../utils/useAuth"
import { verifySignInLink } from "../utils/verifySignInLink"
import { Prices, TEST_RES } from "../res"

if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_TOKEN as string, {
    api_host: "https://eu.posthog.com",
    opt_out_capturing_by_default: true,
  })
}

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const getInitState = () => {
  if (typeof window === "undefined") {
    return undefined
  }
  const local = window.localStorage?.getItem("cookieOptIn")
  if (local === "1") {
    return true
  }
  if (local === "0") {
    return false
  }
  return undefined
}

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  // Posthog
  const [acceptedCookies, setAcceptedCookies] = useState(getInitState)
  const router = useRouter()
  const replace = router.replace

  useEffect(() => {
    // Check if we're coming from the plugin, appending the Figma user ID as a
    // search param.
    const figmaUserId = router.query.fid
    if (figmaUserId && typeof figmaUserId === "string") {
      // User has already opted in to our privacy policy in the plugin.
      if (!posthog.has_opted_in_capturing()) {
        posthog.opt_in_capturing()
      }
      if (!posthog.get_distinct_id()) {
        posthog.identify(figmaUserId)
      }
      // Remove the search param from the URL.
      const route = router.asPath.split("?")[0]
      if (route) {
        replace(route, undefined, { shallow: true })
      }
    }
  }, [replace, router.asPath, router.query.fid])

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => {
      posthog.capture("$pageview")
    }
    router.events.on("routeChangeComplete", handleRouteChange)
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router.events])

  // One-time fetch on mount, doesn't update (by design so that we can display shouldActivate even after activation)
  const [frontenderSettings, setFrontenderSettings] =
    useState<Frontender.RemoteDoc>()
  const [prices, setPrices] = useState<Prices>()
  const [error, setError] = useState(false)
  const { user } = useAuth()
  useEffect(() => {
    if (!user?.uid) {
      verifySignInLink()
      return
    }
    const q = query(
      collection(db, "frontenderSettings"),
      where("userId", "==", user?.uid)
    )
    getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const d = doc.data() as Frontender.RemoteDoc
        if (d?.figmaUserId) {
          posthog.identify(d.figmaUserId)
        }
        const obj: Partial<
          Record<keyof typeof d | "email" | "userId", string | boolean | number>
        > = {
          ...d,
        }
        if (user.email) {
          obj.email = user.email
        }
        if (user.uid) {
          obj.userId = user.uid
        }
        posthog.capture("website_login")
        setFrontenderSettings(d)
      })
    })
  }, [user?.email, user?.uid])
  useEffect(() => {
    fetchJsonp(
      "https://checkout.paddle.com/api/2.0/prices?product_ids=814517%2C814518%2C814520%2C814521"
    )
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        if (data.success) {
          setPrices(data.response.products)
        } else {
          setError(true)
        }
      })
      .catch(() => {
        setPrices(TEST_RES.response.products)
        setError(true)
      })
  }, [])
  const getLayout = Component.getLayout ?? ((page) => page)
  return (
    <>
      {getLayout(
        <Component
          {...pageProps}
          prices={prices}
          error={error}
          frontenderSettings={frontenderSettings}
          acceptedCookies={acceptedCookies}
          setAcceptedCookies={setAcceptedCookies}
        />
      )}
      <Script
        src="https://cdn.paddle.com/paddle/paddle.js"
        // eslint-disable-next-line react/jsx-no-bind
        onReady={() => {
          if (
            process.env.NODE_ENV === "development" ||
            process.env.NEXT_PUBLIC_VERCEL_ENV === "preview"
          ) {
            ;(window as any).Paddle.Environment.set("sandbox")
          }
          ;(window as any).Paddle.Setup({
            vendor: parseInt(
              process.env.NEXT_PUBLIC_PADDLE_VENDOR_ID as string
            ),
          })
        }}
      />
    </>
  )
}
