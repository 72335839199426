export type Prices = typeof TEST_RES.response.products

export const TEST_RES = {
  response: {
    customer_country: "NL",
    products: [
      {
        applied_coupon: [],
        currency: "EUR",
        list_price: { gross: 12.1, net: 10.0, tax: 2.1 },
        price: { gross: 12.1, net: 10.0, tax: 2.1 },
        product_id: 814517,
        product_title: "Professional Monthly",
        subscription: {
          frequency: 1,
          interval: "month",
          list_price: { gross: 12.1, net: 10.0, tax: 2.1 },
          price: { gross: 12.1, net: 10.0, tax: 2.1 },
          trial_days: 0,
        },
        vendor_set_prices_included_tax: false,
      },
      {
        applied_coupon: [],
        currency: "EUR",
        list_price: { gross: 108.9, net: 90.0, tax: 18.9 },
        price: { gross: 108.9, net: 90.0, tax: 18.9 },
        product_id: 814521,
        product_title: "Professional Annually",
        subscription: {
          frequency: 1,
          interval: "year",
          list_price: { gross: 108.9, net: 90.0, tax: 18.9 },
          price: { gross: 108.9, net: 90.0, tax: 18.9 },
          trial_days: 0,
        },
        vendor_set_prices_included_tax: false,
      },
    ],
  },
  success: true,
}
