import {
  FirebaseApp,
  FirebaseOptions,
  getApps,
  initializeApp,
} from "firebase/app"
import { Auth, connectAuthEmulator, getAuth } from "firebase/auth"
import {
  Firestore,
  connectFirestoreEmulator,
  getFirestore,
} from "firebase/firestore"

const isLocal =
  typeof window !== "undefined" ? location.hostname === "localhost" : true

const config: FirebaseOptions = isLocal
  ? {
      apiKey: "--------------------------",
      authDomain: "demo-webbie.firebaseapp.com",
      projectId: "demo-webbie",
    }
  : {
      apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
      appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
      authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
      messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_SENDER_ID,
      projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
      storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    }

/**
 * Creates a Firebase app if one doesn't exist, otherwise returns the existing
 * app.
 */
function getFirebaseApp(config: FirebaseOptions): {
  db: Firestore
  auth: Auth
} {
  // Get the active app or create one.
  const apps = getApps()
  const app = apps.length > 0 ? (apps[0] as FirebaseApp) : initializeApp(config)

  // Get the services.
  const db = getFirestore(app)
  const auth = getAuth(app)

  // Connect to emulator if running locally.
  if (isLocal) {
    connectAuthEmulator(auth, "http://localhost:9099")
    connectFirestoreEmulator(db, "localhost", 8080)
  }

  return { auth, db }
}

export const { db, auth } = getFirebaseApp(config)
