import { User, getAuth, onAuthStateChanged } from "firebase/auth"
import { useEffect, useState } from "react"
// eslint-disable-next-line import/no-unassigned-import
import "./init"

export function useAuth(): {
  authStatus: "loading" | "success" | "error"
  user: User | undefined
} {
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState<User | undefined>(undefined)
  const [error, setError] = useState<Error | undefined>(undefined)
  const auth = getAuth()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        if (user) {
          setUser(user)
        } else {
          setUser(undefined)
        }
        setIsLoading(false)
      },
      (error) => {
        setError(error)
        setIsLoading(false)
      }
    )
    return () => unsubscribe()
  }, [auth])

  return {
    authStatus: error ? "error" : isLoading ? "loading" : "success",
    user,
  }
}
